import DatePickerCustom from "components/Table/datePicker";
import useDocumentTitle from "../../useDocumentTitle";
import TableComponent from "components/Table/index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React from "react";

const MyLotteries = () => {
  const history = useHistory();
  const { t } = useTranslation();
  useDocumentTitle(`${t("lotteries.my-lotteries")} | ${t("site-name")}`);

  const lotteries = React.useMemo(
    () => [
      { value: "megamillions", text: "Mega Millions" },
      { value: "powerball", text: "Powerball" },
      { value: "euromillions", text: "Euro Millions" },
      { value: "eurojackpot", text: "Eurojackpot" },
      { value: "el-gordo-primitiva", text: "El Gordo" },
      { value: "la-primitiva", text: "La Primitiva" },
      { value: "megasena", text: "Mega-Sena" },
      { value: "superenalotto", text: "SuperEnalotto" },
      { value: "lotto-america", text: "Lotto America" },
      { value: "lotto-6aus49", text: "Lotto 6aus49" },
      { value: "sat-lotto-au", text: "Saturday Lotto" },
      { value: "powerball-au", text: "Powerball AU" },
      { value: "lotto-fr", text: "French Lotto" },
      { value: "lotto-uk", text: "UK Lottery" },
      { value: "lotto-ny-us", text: "New York Lotto" },
      { value: "thunderball", text: "Thunderball" },
      { value: "set-for-life", text: "Set For Life" },
    ],
    []
  );

  const statuses = React.useMemo(
    () => [
      { value: "pending-draw", text: "Pending Draw" },
      { value: "won", text: "Won" },
      { value: "lost", text: "Lost" },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "transaction_id",
        header: t("lotteries.fields.ticket-id"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
      },
      {
        accessorKey: "lottery",
        header: t("lotteries.fields.lottery"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterVariant: "select",
        filterSelectOptions: lotteries,
        Cell: ({ cell }) =>
          lotteries.find((lottery) => lottery.value === cell.getValue())?.text,
      },
      {
        accessorKey: "purchased_at",
        accessorFn: (row) => new Date(row.purchased_at),
        id: "purchased_at",
        header: t("lotteries.fields.purchase-date"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleString("en-GB"),
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "draw_date",
        accessorFn: (row) => row.draw_date && new Date(row.draw_date),
        id: " draw_date",
        header: t("lotteries.fields.draw-date"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
          cell.row?.original?.draw_date
            ? new Date(cell.row.original.draw_date).toLocaleDateString("en-GB")
            : "-",
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "price",
        header: t("lotteries.fields.amount"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original.price.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "status",
        header: t("lotteries.fields.status"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterVariant: "select",
        filterSelectOptions: statuses,
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <span
              className={`badge ${
                status === "won"
                  ? "badge-success"
                  : status === "pending-draw"
                  ? "badge-warning"
                  : "badge-danger"
              }`}
            >
              {statuses.find((s) => s.value === status)?.text}
            </span>
          );
        },
      },
      {
        accessorKey: "win_amount",
        header: t("lotteries.fields.win-amount"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original?.win_amount?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "actions",
        enableColumnFilter: false,
        enableGrouping: false,
        enableSorting: false,
        header: t("lotteries.fields.action"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) => (
          <button
            className="btn btn-simbalotto-orange text-uppercase"
            onClick={() => {
              history.push(`/my-ticket/${cell.row.original.transaction_id}`);
            }}
          >
            {t("lotteries.fields.view")}
          </button>
        ),
      },
    ],
    [t, lotteries, statuses, history]
  );

  return (
    <div className="row">
      <div className="col-xl-12 mb-3" style={{ zIndex: 0 }}>
        <TableComponent
          title={t("lotteries.my-lotteries")}
          columns={columns}
          url="/api/v1/userLotteries/all?"
        />
      </div>
    </div>
  );
};

export default MyLotteries;
